import React from 'react'

import { Footnote, Spacer } from 'ethos-design-system'

import { CmsLink } from './CmsLink'
import styles from './Footer.module.scss'

interface MenuProps {
  menuHeading: string
  menuItems: Array<{ itemTitle: string; itemUrl: string; linkType: string }>
}

interface FooterMenuProps {
  menu: MenuProps
}

export const FooterMenu: React.FC<FooterMenuProps> = ({ menu }) => {
  return (
    <div className={styles.menu}>
      <div className={styles.menuHeading}>
        <Footnote.Medium500 element="h4">{menu.menuHeading}</Footnote.Medium500>
      </div>
      <nav aria-label="footer link menu">
        {menu.menuItems.map((item, idx) => (
          <div key={idx} className={styles.menuItem}>
            <Footnote.Regular400>
              <CmsLink linkType={item.linkType} itemUrl={item.itemUrl}>
                {item.itemTitle}
              </CmsLink>
            </Footnote.Regular400>
          </div>
        ))}
      </nav>
    </div>
  )
}
