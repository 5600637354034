import React, { useEffect, useState } from 'react'

import Image from 'next/image'

import facebookImg from '@/img/social/facebook-icon-white.svg'
import instagramImg from '@/img/social/instagram-icon-white.svg'
import linkedinImg from '@/img/social/linkedin-icon-white.svg'
import twitterImg from '@/img/social/twitter-icon-white.svg'
import { Caption, Footnote } from 'ethos-design-system'
import Cookies from 'js-cookie'
import get from 'lodash.get'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'

import {
  LEGAL_BANNER_COOKIE,
  LEGAL_BANNER_COOKIE_VALUE,
} from '../../../../constants'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import { CmsLink } from '../CmsLink'
import {
  AGENTS_KEY,
  CONSUMER_FOOTER_MENU_KEYS,
  FOOTER_COPYRIGHT_CONTENT_KEY,
  GLOBAL_KEY,
  universalAccessProLight,
} from '../Footer'
import styles from '../Footer.module.scss'
import {
  DEFAULT_FOOTER_CONTACT_INFO,
  FooterContactInfo,
} from '../FooterContactInfo'

interface PartnerFooterProps {
  settings: Record<string, any>
}

interface FooterContentProps {
  menuHeading: string
  menuItems: Array<{ itemTitle: string; itemUrl: string; linkType: string }>
}

const getPartnerFooterMenusFromContent = (
  footerContent: Array<FooterContentProps>
) => {
  const menuArray = ['/privacy', '/terms/', '/licenses/']
  const menuItems = menuArray.flatMap((itemUrl) =>
    footerContent
      .flatMap((e) => e.menuItems)
      .filter((i) => i.itemUrl === itemUrl)
  )

  return menuItems
}

export const PartnerFooter: React.FC<PartnerFooterProps> = ({ settings }) => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)

  useEffect(() => {
    const legalBannerCookie = Cookies.get(LEGAL_BANNER_COOKIE)
    if (legalBannerCookie !== LEGAL_BANNER_COOKIE_VALUE) {
      setIsDisclaimerOpen(true)
    }
  }, [setIsDisclaimerOpen])

  const agentSettingsContent = useGlobalSettings(AGENTS_KEY, settings)

  const globalSocial = useGlobalSettings(GLOBAL_KEY, settings)

  const { copyrightText } = useGlobalSettings(
    FOOTER_COPYRIGHT_CONTENT_KEY,
    settings
  )
  const footerContent = useGlobalSettings(CONSUMER_FOOTER_MENU_KEYS, settings)

  const marketingClasses = []
  if (isDisclaimerOpen) {
    marketingClasses.push(styles.cookie)
  }

  const { facebookURL, twitterURL, instagramURL, linkedinURL } = globalSocial
  const emailLink = get(
    agentSettingsContent,
    'emailLink',
    DEFAULT_FOOTER_CONTACT_INFO.EMAIL_LINK
  )

  const menu = getPartnerFooterMenusFromContent(footerContent)
  return (
    <footer className={styles.partnerFooter}>
      <div className={styles.container}>
        <div className={styles.contact}>
          <FooterContactInfo
            emailLink={emailLink}
            displayEmail={false}
            phoneNumber={
              DEFAULT_FOOTER_CONTACT_INFO.PARTNER_PHONE_NUMBER.DISPLAY
            }
            phoneLink={DEFAULT_FOOTER_CONTACT_INFO.PARTNER_PHONE_NUMBER.LINK}
          />
        </div>
        <div className={styles.rightPart}>
          <div className={styles.rightUpperPart}>
            <div className={styles.menus}>
              {menu.map((item, idx) => (
                <div className={styles.menuItem} key={idx}>
                  <Footnote.Regular400>
                    <CmsLink linkType={item.linkType} itemUrl={item.itemUrl}>
                      {item.itemTitle}
                    </CmsLink>
                  </Footnote.Regular400>
                </div>
              ))}
            </div>
            <div className={styles.socialIcons}>
              <div className={[styles.icons, styles.inverted].join(' ')}>
                {facebookURL && (
                  <a href={facebookURL}>
                    <Image src={facebookImg} alt="Facebook Icon" />
                  </a>
                )}
                {twitterURL && (
                  <a href={twitterURL}>
                    <Image src={twitterImg} alt="Twitter Icon" />
                  </a>
                )}
                {instagramURL && (
                  <a href={instagramURL}>
                    <Image src={instagramImg} alt="Instagram Icon" />
                  </a>
                )}
                {linkedinURL && (
                  <a href={linkedinURL}>
                    <Image src={linkedinImg} alt="LinkedIn Icon" />
                  </a>
                )}
                <div className={styles.flexStretch} />
              </div>
            </div>
          </div>
          <div className={styles.colophon}>
            <Caption.Regular400>
              <Markdown
                input={copyrightText}
                allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
              />
            </Caption.Regular400>
            <div
              role="button"
              aria-label="universal access menu trigger"
              className={styles.universalAccessContainer}
              id="universal-access-menu-trigger"
            >
              {universalAccessProLight}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
