import React from 'react'

import Link from 'next/link'

import { Link as DsLink } from 'ethos-design-system'
import PropTypes from 'prop-types'
import { Link as ReactScrollLink } from 'react-scroll'

// It's difficult to say where to best write code about "links". For now, the
// style definitions (CSS) live in the design system, but the component files
// should probably live in each app, so each can make use of the right router.

export const CmsLink = ({
  linkType,
  itemUrl,
  children,
  className,
  styleVariant,
}: any) => {
  const classNames = [
    // Default Link styles:
    DsLink.styles.Link,
    // Styles for this particular type of link:
    DsLink.styles[styleVariant],
    // Overrides
    className,
  ]

  if (linkType === CmsLink.LINK_TYPES.INTERNAL) {
    // TODO replace temp fix below w/real fix once bug is understood
    //      internal links to /press and /faq are prefixed w/.../pages
    //      but internal links like /about are not prefixed
    itemUrl = itemUrl.replace('../pages', '')
    return (
      <a className={classNames.join(' ')} href={itemUrl}>
        {children}
      </a>
    )
  }

  if (linkType === CmsLink.LINK_TYPES.FRAGMENT) {
    // Smooth-scrolling fragment links. This may require knowing about media
    // queries and CmsNavbar heights to get the offset correct, unfortunately.
    const fragmentProps = {
      className: classNames.join(' '),
      to: itemUrl, // This seems to be what actually activates the scrolling
      href: `#${itemUrl}`, // Prevent empty `href` and associated HTML issues
      smooth: true,
      duration: 500,
      delay: 0,
    }
    return (
      <ReactScrollLink {...fragmentProps} offset={-96}>
        {children}
      </ReactScrollLink>
    )
  }

  return (
    <a className={classNames.join(' ')} href={itemUrl}>
      {children}
    </a>
  )
}

CmsLink.LINK_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  FRAGMENT: 'fragment', // <a href="#section-1">
}

CmsLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  itemUrl: PropTypes.string.isRequired,
  linkType: PropTypes.oneOf(Object.values(CmsLink.LINK_TYPES)),
  styleVariant: PropTypes.oneOf(Object.values(DsLink.STYLE_VARIANTS)),
}

CmsLink.Inherit = (props: any) => (
  <CmsLink {...props} styleVariant={DsLink.STYLE_VARIANTS.INHERIT} />
)

CmsLink.Standard = (props: any) => (
  <CmsLink
    linkType={CmsLink.LINK_TYPES.INTERNAL}
    {...props}
    styleVariant={DsLink.STYLE_VARIANTS.STANDARD}
  />
)

CmsLink.Fragment = (props: any) => (
  <CmsLink {...props} linkType={CmsLink.LINK_TYPES.FRAGMENT} />
)
