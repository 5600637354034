import React, { FC } from 'react'

import { useFootnoteFooterContext } from '@/contexts'
import { COLORS, Footnote, Layout } from 'ethos-design-system'

import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import styles from './FootnoteFooter.module.scss'

interface IFootnoteFooter {
  footNoteColor?: any
}

const FootnoteFooter: FC<IFootnoteFooter> = ({
  footNoteColor = COLORS.WHITE,
}) => {
  const { displayFootnoteFooter, bodyText } = useFootnoteFooterContext()

  if (!displayFootnoteFooter || !bodyText) return null

  let experimentBodyText = bodyText

  return (
    <Layout.HorizontallyPaddedContainer>
      <div id="footnote" className={styles.root}>
        <Footnote.Regular400 color={footNoteColor}>
          <Markdown
            input={experimentBodyText}
            allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
          />
        </Footnote.Regular400>
      </div>
    </Layout.HorizontallyPaddedContainer>
  )
}

export default FootnoteFooter
