import Footer from './global/Footer/Footer'
import { MarketingFooter } from './global/Footer/MarketingFooter/MarketingFooter'
import { PartnerFooter } from './global/Footer/PartnerFooter/PartnerFooter'

interface FooterComponentProps {
  noFooter: boolean
  partnerFooter: boolean
  blankFooter: boolean
  agentLayout: boolean
  settings: any
  footnoteFooter: any
}

const FooterComponent = ({
  agentLayout,
  blankFooter,
  noFooter,
  partnerFooter,
  settings,
}: FooterComponentProps) => {

  if (noFooter) {
    return null
  } else if (partnerFooter) {
    return <PartnerFooter settings={settings} />
  } else if (blankFooter) {
    return <MarketingFooter settings={settings} />
  }
  return <Footer agentLayout={agentLayout} settings={settings} />
}

export default FooterComponent
