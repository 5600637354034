import React from 'react'

import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import { COLORS, Caption, Layout, Spacer } from 'ethos-design-system'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'

import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import {
  FOOTER_COPYRIGHT_CONTENT_KEY,
  universalAccessProLight,
} from '../Footer'
import styles from '../Footer.module.scss'
import FootnoteFooter from '../FootnoteFooter/FootnoteFooter'

interface MarketingFooterProps {
  settings: Record<string, any>
}

export const MarketingFooter: React.FC<MarketingFooterProps> = ({
  settings,
}) => {
  const { isDisclaimerOpen } = useLegalBannerContext()

  const { copyrightText } = useGlobalSettings(
    FOOTER_COPYRIGHT_CONTENT_KEY,
    settings
  )

  const marketingClasses = []
  if (isDisclaimerOpen) {
    marketingClasses.push(styles.blankFooter)
  }

  return (
    <footer>
      <FootnoteFooter footNoteColor={COLORS.GRAY_SECONDARY} />
      <Spacer.H24 />
      <Layout.HorizontallyPaddedContainer>
        <div className={marketingClasses.join(' ')}>
          <div className={styles.marketingFooterColophon}>
            <Caption.Regular400>
              <Markdown
                input={copyrightText}
                allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
              />
            </Caption.Regular400>
          </div>
          <div
            role="button"
            aria-label="universal access menu trigger"
            className={styles.universalAccessContainer}
            id="universal-access-menu-trigger"
          >
            {universalAccessProLight}
          </div>
        </div>
      </Layout.HorizontallyPaddedContainer>
    </footer>
  )
}
